<ng-container>
  <div id="portal-top-menu-stripe">
    <portal-navbar></portal-navbar>

    <router-outlet />
  </div>
</ng-container>

<loading-indicator [loadingService]="loadingService" />
<error-indicator />
<p-toast [position]="toastPosition" [life]="1500" />
