import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';

import {from, Observable} from 'rxjs';
import {concatMap, map} from 'rxjs/operators';
import {KeycloakService} from 'keycloak-angular';
import {UserSessionService} from '../services/user-session.service';
import {Utils} from '../utils/utils';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private keycloakAngular: KeycloakService,
    private readonly userSessionService: UserSessionService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const tokenPromise: Promise<string> = this.keycloakAngular.getToken();
    const tokenObservable: Observable<string> = from(tokenPromise);

    return tokenObservable.pipe(
      map(authToken => {
        let headers = {Authorization: 'Bearer ' + authToken};
        const impersonationHash: string = this.userSessionService.getPreviewAsCompanyHash();
        if (Utils.isNotEmpty(impersonationHash)) {
          headers = {...headers, ...{Hash: impersonationHash}};
        }
        req = req.clone({ setHeaders: headers});
      }),
      concatMap(request => {
        return next.handle(req);
      }));
  }
}
