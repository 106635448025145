<p-dialog header="Načítava sa ..." id="loading-block"
          *ngIf="loadingService?.show"
          [visible]="loadingService.show"
          [modal]="true"
          [style]="{width: '200px'}"
          [maximizable]="false"
          [baseZIndex]="10000"
          [closable]="false"
          [showHeader]="true">
    <div id="loading-content">
        <p-progressSpinner
                strokeWidth="4">
        </p-progressSpinner>
    </div>
</p-dialog>
