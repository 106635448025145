<ng-container *ngFor="let dialogError of dialogErrors; let i = index">
  <p-dialog [header]="dialogError.title"
            [(visible)]="showDialogError"
            [modal]="true"
            [style]="{width: (dialogError.width ? dialogError.width : '500px')}"
            [maximizable]="false" [baseZIndex]="10000"
            [resizable]="false" [draggable]="false"
            (visibleChange)="hideDialog(i, $event)">
    <span class="print-dialog-label" style="line-height: 30px;"
          [style.white-space]="dialogError.multipleLines ? 'break-spaces' : 'inherit'">{{ dialogError.message }}</span>
    <p-footer>
      <p-button icon="pi pi-check" (click)="hideDialog(i)" label="OK"></p-button>
    </p-footer>
  </p-dialog>
</ng-container>
