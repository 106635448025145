import {Component, Input} from '@angular/core';
import {LoadingService} from './loading.service';

@Component({
    selector: "loading-indicator",
    templateUrl: "./loading.component.html",
    styleUrls: ["./loading.component.less"]
})
export class LoadingComponent {

    @Input() loadingService!: LoadingService;
}
