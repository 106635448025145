import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot} from '@angular/router';
import {catchError, concat, Observable, of, tap, toArray} from 'rxjs';
import {LoadingService} from '../components/loading/loading.service';
import {ContentDetail} from '../openapi/client/models/content-detail';
import {ContentService} from '../openapi/client/services/content.service';
import {Utils} from '../utils/utils';
import {GetContent$Params} from '../openapi/client/fn/content/get-content';
import {MenuService} from '../openapi/client/services/menu.service';
import {map} from 'rxjs/operators';
import {GetMenuSectionsResponse} from '../openapi/client/models/get-menu-sections-response';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

/**
 * Resolver nacita hlavne menu a ak je v url query parameter id - dotiahne konkretny content
 * @param route
 * @param state
 * @param loadingService
 * @param menuService
 * @param contentService
 * @constructor
 */
export const ContentResolver: ResolveFn<any | undefined> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    loadingService: LoadingService = inject(LoadingService),
    menuService: MenuService = inject(MenuService),
    contentService: ContentService = inject(ContentService)
): Observable<any | undefined> => {
    loadingService.showLoading();
    let sources = [];
    sources.push(menuService.getMenuSections().pipe(
        takeUntilDestroyed(),
        map((response: GetMenuSectionsResponse) => ({menuSections: response.menuSections})),
        catchError(() => of(undefined))
    ));

    const urlParams = Utils.getUrlParams(state.url);
    let lastSectionId;
    if (urlParams) {
        const params = urlParams.split('/');
        lastSectionId = Utils.getLastParam(params.map(Number));
    }
    const queryParams = route.queryParams as any;
    if (queryParams) {
        const content: string = queryParams.id;
        if (Utils.isNotEmpty(content)) {
            const request = {id: Number(content), sectionId: lastSectionId} as GetContent$Params;
            sources.push(contentService.getContent(request).pipe(
                takeUntilDestroyed(),
                map((contentDetail: ContentDetail) => ({contentDetail})),
                catchError(() => of(undefined))
            ));
        }
    }
    return concat(...sources).pipe(
        takeUntilDestroyed(),
        toArray(),
        tap(() => loadingService.hideLoading()),
        map((responses: any[]) => Object.assign({}, ...responses)),
        catchError(() => {
            loadingService.hideLoading();
            return of(undefined);
        }));
};
