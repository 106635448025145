import {Component} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Utils} from '../../utils/utils';
import {KeycloakService} from 'keycloak-angular';
import {UserSessionService} from '../../services/user-session.service';
import {UserType} from '../../types/user.type';
import {UserUtil} from '../../utils/user-utils';
import {MenuItem} from './menu.item';
import {MobileMenuService} from '../mobile-menu/mobile-menu.service';
import {ContentMenuService} from "../../../content/content-menu/content-menu.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

@Component({
  selector: 'portal-navbar',
  templateUrl: './navigation.bar.component.html'
})
export class NavigationBarComponent {

  user: UserType | undefined;
  userInitials: string | undefined = '';
  userScreenName: string | undefined = 'Neautorizovaný';
  userEmail: string | undefined = '';
  isAdminOrRedactor = false;
  company: string | undefined;
  logged = false;
  renderMap: Map<string, boolean> = new Map();
  menuItems: MenuItem[] = [];
  selectedMenu: string | undefined;
  isAdminSection = false;
  isImpersonation = false;
  previewAsCompany: string | undefined;
  contactsMenuPath: string = '';

  constructor(private readonly router: Router, private readonly keycloakService: KeycloakService,
              private readonly userSessionService: UserSessionService, private readonly mobileMenuService: MobileMenuService,
              private readonly contentMenuService: ContentMenuService) {
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.isAdminSection = e.url.includes('admin');
        // ked uz je pouzivatel prihlaseny a je ulozeny do session
        this.user = this.userSessionService.getUser();
        this.isAdminOrRedactor = UserUtil.isAdminOrRedactor(this.user);
        this.userScreenName = this.userSessionService.getUserScreenName();

        if (Utils.isNotEmpty(this.userScreenName)) {
          this.logged = true;
          this.createMapForRenderItems();
          this.userInitials = Utils.getUserInitials(this.userScreenName);
          this.company = this.userSessionService.getUserCompany();
          this.userEmail = this.userSessionService.getUserEmail();
        }

        const urlParams = Utils.getUrlParams(e.url);
        if (urlParams) {
          const params = urlParams.split('/');
          const section = Utils.getLastParam(params);
          if (this.isAdminOrRedactor) {
            const activeSection = this.menuItems.find(menuItem => menuItem.id === section);
            this.selectedMenu = activeSection?.id || this.menuItems[0].id;
          } else {
            this.selectedMenu = section;
          }
        } else {
          this.selectedMenu = this.isAdminOrRedactor && this.isAdminSection ? this.menuItems[0].id : undefined;
        }

        this.isImpersonation = this.userSessionService.isImpersonation();
        if (this.isImpersonation) {
          this.previewAsCompany = this.userSessionService.previewAsCompany();
        }
      }
    });

    this.contentMenuService.contactsMenuPathSubject
        .pipe(takeUntilDestroyed())
        .subscribe((contactMenuPath: string | undefined) => {
          this.contactsMenuPath = contactMenuPath || '';
        });
  }

  createMapForRenderItems(): void {
    this.renderMap.set('displayContactsForm', !this.isAdminOrRedactor);
    this.renderMap.set('displayContacts', !this.isAdminSection);
    this.renderMap.set('displayAdministration', this.isAdminOrRedactor && !this.isAdminSection);
    this.renderMap.set('displayMenu', this.isAdminOrRedactor && this.isAdminSection);
    if (this.isAdminOrRedactor) {
      this.menuItems = [];
      this.menuItems.push({
        id: 'content',
        label: 'Obsah'
      });
      this.menuItems.push({
        id: 'partners',
        label: 'EDS Partneri'
      });
      this.menuItems.push({
        id: 'agents',
        label: 'Agenti'
      });
      this.menuItems.push({
        id: 'reports',
        label: 'Reporty'
      });
      this.selectedMenu = this.menuItems[0].id;
    }

    this.mobileMenuService.renderMap = this.renderMap;
  }

  onAdminMenuClick(menuId: string | undefined): void {
    this.router.navigate(['/admin/' + menuId]);
  }

  logoutRhSso(): void {
    this.userSessionService.clearSession();
    this.keycloakService.logout();
  }

  openPreviewAsCompanyDialog() {
    this.userSessionService.openPreviewAsCompanyDialog();
  }

  openUserAccount() {
    if (!this.isAdminOrRedactor) {
      this.router.navigate(['/user']);
    }
  }

  showMobileMenu(): void {
    this.mobileMenuService.showMobileMenuSubject.next(true);
  }
}
