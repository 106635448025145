/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { MenuService } from './services/menu.service';
import { CodelistService } from './services/codelist.service';
import { UserService } from './services/user.service';
import { ContentService } from './services/content.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    MenuService,
    CodelistService,
    UserService,
    ContentService,
    ApiConfiguration
  ],
})
export class AdminModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<AdminModule> {
    return {
      ngModule: AdminModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: AdminModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('AdminModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
