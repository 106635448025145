import {KeycloakService} from 'keycloak-angular';
import {environment} from "../environments/environment";
import {UserSessionService} from './shared/services/user-session.service';

// load the Keycloak JavaScript adapter
export function initializeKeycloak(keycloak: KeycloakService, userSessionService: UserSessionService): () => Promise<any> {
  return (): Promise<any> => {
    return keycloak.init({
      config: environment.keycloak,
      initOptions: {
        checkLoginIframe: environment.checkLoginIframe,
        onLoad: 'login-required',
        pkceMethod: 'S256'
      }}).then(() => userSessionService.loadUserProfile());
  };
}
