import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {KeycloakAuthGuard, KeycloakService} from 'keycloak-angular';
import {UserUtil} from '../utils/user-utils';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard extends KeycloakAuthGuard {
  constructor(
    protected override readonly router: Router,
    protected readonly keycloak: KeycloakService
  ) {
    super(router, keycloak);
  }

  public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.origin + state.url
      });
    }

    // Get the roles required from the route.
    const requiredRoles: string[] = route.data['roles'];

    // Allow the user to proceed if no additional roles are required to access the route.
    if (!Array.isArray(requiredRoles) || requiredRoles.length === 0) {
      return true;
    }

    // Allow the user to proceed if somme of the required roles are present.
    const isAccessAllowed = requiredRoles.some((role) => this.roles.includes(role));
    if (!isAccessAllowed) {
      this.router.navigate([UserUtil.getDefaultRoute(this.roles)]);
      return isAccessAllowed;
    } else {
      // Allow the user to proceed if none of rejected roles is present
      const rejectedRoles: string[] | undefined = route.data['rejectedRoles'];
      if (rejectedRoles) {
        const isAccessDenied = rejectedRoles.some((role) => this.roles.includes(role));
        if (isAccessDenied) {
          this.router.navigate([UserUtil.getDefaultRoute(this.roles)]);
        }
        return !isAccessDenied;
      } else {
        return isAccessAllowed;
      }
    }
  }
}
