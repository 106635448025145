import {Injectable} from "@angular/core";
import {environment} from "../environments/environment";

@Injectable()
export class AppService {
  private static apiUrl = environment.settings.apiUrl;

  static getApiUrl(): string {
    return this.apiUrl;
  }
}
